import {
  ActiveFeatures,
  CatalogServiceDto,
  ServiceType,
} from '@wix/bookings-uou-types';
import { ReferralInfo } from '../../utils/bi/consts';
import {
  BookingValidations,
  getNotifyPricingPlanRequest,
} from '../../utils/validation/booking-validations';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { BookingsApiDeprecated } from '../../api/BookingsApiDeprecated';
import { openServicesPreferencesModalAction } from '../openServicesPreferencesModal/openServicesPreferencesModal';
import { NavigateToNextPageAction } from '../navigateToNextPage/navigateToNextPage';
import { NavigationTarget } from '../navigateToNextPage/consts';
import { bookingsCantBookGroupsMessage } from '@wix/bi-logger-wixboost-ugc/v2';
import { OpenPremiumDialogAction } from '../openPremiumDialog/openPremiumDialog';
import { WidgetConfig } from '../../../legacy/types';
import { ServiceIntent } from '../../consts';
import { ServiceDeprecated } from '../../types/types';

export type OnBookFlowStartAttemptAction = ({
  service,
  referralInfo,
}: {
  service: CatalogServiceDto;
  referralInfo: ReferralInfo;
}) => Promise<void>;

export const createOnBookFlowStartAttemptAction = ({
  flowAPI,
  bookingsApi,
  config,
  openServicesPreferencesModal,
  navigateToNextPage,
  openPremiumDialog,
}: {
  flowAPI: ControllerFlowAPI;
  bookingsApi: BookingsApiDeprecated;
  config: WidgetConfig;
  openServicesPreferencesModal: openServicesPreferencesModalAction;
  openPremiumDialog: OpenPremiumDialogAction;
  navigateToNextPage: NavigateToNextPageAction;
}): OnBookFlowStartAttemptAction => {
  return async ({
    service,
    referralInfo,
  }: {
    service: ServiceDeprecated;
    referralInfo: ReferralInfo;
  }) => {
    try {
      if (service.blockNavigationReason) {
        if (
          service.blockNavigationReason.multiServiceAppointment &&
          !flowAPI.experiments.enabled(
            'specs.bookings.msaNotPartOfBlockNavigation',
          )
        ) {
          await openServicesPreferencesModal({ service });
        } else {
          if (service.blockNavigationReason.premiumError) {
            bookingsApi.notifyOwnerNonPremiumEnrollmentAttempt({
              service,
            });
          } else if (service.blockNavigationReason.pricingPlanError) {
            bookingsApi.notifyOwnerNonPricingPlanEnrollmentAttempt(
              getNotifyPricingPlanRequest(
                service,
                service.blockNavigationReason,
              ),
            );
          }
          flowAPI.bi?.report(
            bookingsCantBookGroupsMessage({
              referralInfo: ReferralInfo.WIDGET,
              failReason: service.blockNavigationReason?.siteNotEcomError
                ? 'non ecom site'
                : undefined,
            }),
          );

          await openPremiumDialog();
        }
      } else {
        if (
          service.multiServiceAppointmentActivated &&
          flowAPI.experiments.enabled(
            'specs.bookings.msaNotPartOfBlockNavigation',
          )
        ) {
          await openServicesPreferencesModal({ service });
        } else {
          await navigateToNextPage({
            service,
            referralInfo,
            target: NavigationTarget.CALENDAR_PAGE,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};
