import { WidgetViewModel } from '../../viewModelDeprecated/viewModel';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export type OnDialogCloseAction = () => void;

export const createOnDialogCloseAction = ({
  widgetViewModel,
  setProps,
  flowAPI,
}: {
  widgetViewModel: WidgetViewModel;
  setProps: Function;
  flowAPI: ControllerFlowAPI;
}): OnDialogCloseAction => {
  return () => {
    widgetViewModel.dialogViewModel.isOpen = false;

    setProps({
      widgetViewModel: { ...widgetViewModel },
    });
  };
};
