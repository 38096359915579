import { CatalogServiceDto, ServiceType } from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export function shouldOpenServicesPreferencesModal({
  flowAPI,
  service,
  isMultiServicesAppointmentsEnable,
}: {
  flowAPI: ControllerFlowAPI;
  service: CatalogServiceDto;
  isMultiServicesAppointmentsEnable: boolean;
}): boolean {
  return (
    flowAPI.experiments.enabled('specs.bookings.multiServiceAppointment') &&
    isMultiServicesAppointmentsEnable &&
    !service.dummy &&
    service.type === ServiceType.INDIVIDUAL
  );
}
