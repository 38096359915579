import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { NavigateToNextPageAction } from '../navigateToNextPage/navigateToNextPage';
import settingsParams from '../../components/BookOnline/settingsParams';
import { ServiceClickTarget } from '../../types/types';
import { CatalogServiceDto, ServiceType } from '@wix/bookings-uou-types';
import { ReferralInfo } from '../../utils/bi/consts';
import { NavigationTarget } from '../navigateToNextPage/consts';
import { OnBookFlowStartAttemptAction } from '../onBookFlowStartAttempt/onBookFlowStartAttempt';
import { WidgetViewModel } from '../../viewModelDeprecated/viewModel';

export type OnServiceClickAction = ({
  service,
  referralInfo,
}: {
  service: CatalogServiceDto;
  referralInfo: ReferralInfo;
}) => Promise<void>;

export const createOnServiceClickAction = ({
  navigateToNextPage,
  flowAPI,
  onBookFlowStartAttempt,
}: {
  navigateToNextPage: NavigateToNextPageAction;
  flowAPI: ControllerFlowAPI;
  onBookFlowStartAttempt: OnBookFlowStartAttemptAction;
}): OnServiceClickAction => {
  return async ({
    service,
    referralInfo,
  }: {
    service: CatalogServiceDto;
    referralInfo: ReferralInfo;
  }) => {
    if (
      service.schedulePolicy.displayOnlyNoBookFlow ||
      service.type === ServiceType.COURSE ||
      flowAPI.settings.get(settingsParams.serviceClickTarget) ===
        ServiceClickTarget.SERVICE_PAGE
    ) {
      return navigateToNextPage({
        target: NavigationTarget.SERVICE_PAGE,
        service,
        referralInfo,
      });
    } else {
      return onBookFlowStartAttempt({ service, referralInfo });
    }
  };
};
